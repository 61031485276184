import * as React from "react"
import Layout from "../components/layout"
import GalleryCroquis from "../components/GalleryCroquis"

const GalleryPageCroquis = () => (
  <Layout>
    <GalleryCroquis />
  </Layout>
)

export default GalleryPageCroquis